import withPageTitle from 'HOCs/withPageTitle';
import Settings from './Settings';
import ChangePassword from './ChangePassword';
import styles from './profileSettings.css';
import Api from './Api';
import OptOut from './OptOut';
import Licenses from './Licenses';

@withPageTitle('Account - Stack Analytix Replay Preferences')
export default class ProfileSettings extends React.PureComponent {  
  render() {    
    return (
      <React.Fragment>
       
      </React.Fragment>
    );
  }
}
