import React from 'react';
import { Icon } from 'UI';
import stl from './filterSelectionButton.css';
import classNames from 'classnames';

const FilterSelectionButton = ({ label }) => {
  return (
    <div className={ classNames(stl.wrapper,'filterSelectionButton_wrapper') }>
      <span className="capitalize">{ label } </span>
      <Icon name="chevron-down"/>
    </div>
  );
};

export default FilterSelectionButton;
