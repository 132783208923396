import React from 'react' 
import ManageUsers from '../../../Client/ManageUsers'

export default function ManageUsersTab() {
  return (
    <div className="flex pt-8 -mx-4">
      
    </div>
  )
}
