import {
  createStore,
  applyMiddleware
} from 'redux';
import thunk from 'redux-thunk';
import {
  Map
} from 'immutable';
import indexReducer from './duck';
import apiMiddleware from './api_middleware';
import LocalStorage from './local_storage';

const storage = new LocalStorage({
  jwt: String,
});

const storageState = storage.state();
const initialState = Map({
  jwt: storageState.jwt,
  // TODO: store user
});
var urlSearchParams = new URLSearchParams(window.location.search);
var sessionkey = urlSearchParams.get('sessionkey');

var sessiontoken = getCookie('token');
if (sessionkey != null && sessionkey != "") {
  localStorage.setItem("sessionkey", sessionkey);
  sessionkey = "%_END" + sessionkey;
} else {
  if (sessiontoken) {
    sessionkey = sessiontoken;
    localStorage.setItem("sessionkey", sessionkey);
    sessionkey = "%_END" + sessionkey;
  }
}
const store = createStore(indexReducer, initialState, applyMiddleware(thunk, apiMiddleware));
store.subscribe(() => {
  const state = store.getState();
  storage.sync({
    jwt: sessionkey || state.get('jwt')
  });
});

function getCookie(cookieName) {
  var name = cookieName + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if ((c.indexOf(name)) == 0) {
      return c.substr(name.length);
    }

  }
  return null;
}

export default store;