var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import VideoContainer from '../components/VideoContainer';
import cn from 'classnames';
import Counter from 'App/components/shared/SessionItem/Counter';
import stl from './chatWindow.css';
import ChatControls from '../ChatControls/ChatControls';
import Draggable from 'react-draggable';
var ChatWindow = function ChatWindow(_a) {
    var userId = _a.userId, incomeStream = _a.incomeStream, localStream = _a.localStream, endCall = _a.endCall;
    var _b = __read(useState(false), 2), minimize = _b[0], setMinimize = _b[1];
    return (React.createElement(Draggable, { handle: ".handle", bounds: "body" },
        React.createElement("div", { className: cn(stl.wrapper, "fixed radius bg-white shadow-xl mt-16"), style: { width: '280px' } },
            React.createElement("div", { className: "handle flex items-center p-2 cursor-move select-none" },
                React.createElement("div", { className: stl.headerTitle },
                    React.createElement("b", null, "Meeting"),
                    " ",
                    userId),
                React.createElement(Counter, { startTime: new Date().getTime(), className: "text-sm ml-auto" })),
            React.createElement("div", { className: cn(stl.videoWrapper, { 'hidden': minimize }, 'relative') },
                React.createElement(VideoContainer, { stream: incomeStream }),
                React.createElement("div", { className: "absolute bottom-0 right-0 z-50" },
                    React.createElement(VideoContainer, { stream: localStream, muted: true, width: 50 }))),
            React.createElement(ChatControls, { stream: localStream, endCall: endCall }))));
};
export default ChatWindow;
