var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { Popup, Icon } from 'UI';
import { connect } from 'react-redux';
import cn from 'classnames';
import { toggleChatWindow } from 'Duck/sessions';
import { connectPlayer } from 'Player/store';
import ChatWindow from '../../ChatWindow';
import { callPeer } from 'Player';
import { CallingState, ConnectionStatus } from 'Player/MessageDistributor/managers/AssistManager';
import { toast } from 'react-toastify';
import stl from './AassistActions.css';
function AssistActions(_a) {
    var _b, _c;
    var toggleChatWindow = _a.toggleChatWindow, userId = _a.userId, calling = _a.calling, peerConnectionStatus = _a.peerConnectionStatus;
    var _d = __read(useState(null), 2), incomeStream = _d[0], setIncomeStream = _d[1];
    var _e = __read(useState(null), 2), localStream = _e[0], setLocalStream = _e[1];
    var _f = __read(useState(function () { }), 2), endCall = _f[0], setEndCall = _f[1];
    useEffect(function () {
        return endCall;
    }, []);
    useEffect(function () {
        if (peerConnectionStatus == ConnectionStatus.Disconnected) {
            toast.info("Live session was closed.");
        }
    }, [peerConnectionStatus]);
    function onClose(stream) {
        stream.getTracks().forEach(function (t) { return t.stop(); });
    }
    function onReject() {
        toast.info("Call was rejected.");
    }
    function onError() {
        toast.error("Something went wrong!");
    }
    function call() {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(function (lStream) {
            setLocalStream(lStream);
            setEndCall(function () { return callPeer(lStream, setIncomeStream, onClose.bind(null, lStream), onReject, onError); });
        }).catch(onError);
    }
    var inCall = calling !== CallingState.False;
    return (React.createElement("div", { className: "flex items-center" },
        React.createElement(Popup, { trigger: React.createElement("div", { className: cn('cursor-pointer p-2 mr-2 flex items-center', (_b = {}, _b[stl.inCall] = inCall, _b), (_c = {}, _c[stl.disabled] = peerConnectionStatus !== ConnectionStatus.Connected, _c)), onClick: inCall ? endCall : call, role: "button" },
                React.createElement(Icon, { name: "headset", size: "20", color: inCall ? "red" : "gray-darkest" }),
                React.createElement("span", { className: cn("ml-2", { 'text-red': inCall }) }, inCall ? 'End Call' : 'Call')), content: "Call " + userId, size: "tiny", inverted: true, position: "top right" }),
        React.createElement("div", { className: "fixed ml-3 left-0 top-0", style: { zIndex: 999 } }, inCall && React.createElement(ChatWindow, { endCall: endCall, userId: userId, incomeStream: incomeStream, localStream: localStream }))));
}
var con = connect(null, { toggleChatWindow: toggleChatWindow });
export default con(connectPlayer(function (state) { return ({
    calling: state.calling,
    peerConnectionStatus: state.peerConnectionStatus,
}); })(AssistActions));
