import React from 'react';
import { connect } from 'react-redux';
import stl from './savedSearchList.css';
import cn from 'classnames';
import { Icon, IconButton, Loader, Button } from 'UI';
import { confirm } from 'UI/Confirmation';
import { withRouter } from 'react-router-dom';
import { addEvent } from 'Duck/filters';
import {
    fetchList as fetchFunnelsList,
    applySavedFilter,
    remove as deleteSearch,
    setActiveFlow,
    clearEvents,
    init
} from 'Duck/funnels';
import { setActiveTab } from 'Duck/sessions';
import { funnel as funnelRoute, withSiteId } from 'App/routes';
import Event, { TYPES } from 'Types/filter/event';
import FunnelMenuItem from 'Components/Funnels/FunnelMenuItem';
import FunnelSaveModal from 'Components/Funnels/FunnelSaveModal';
import NewBadge from 'Shared/NewBadge';
import { blink as setBlink } from 'Duck/funnels';

const DEFAULT_VISIBLE = 3;
@withRouter
class SavedSearchList extends React.Component {
    state = { showMore: false, showSaveModal: false }

    setFlow = flow => {
        this.props.setActiveTab({ name: 'All', type: 'all' });
        this.props.setActiveFlow(flow)
        if (flow && flow.type === 'flows') {
            this.props.clearEvents()
        }
    }

    renameHandler = funnel => {        
        this.props.init(funnel);
        this.setState({ showSaveModal: true })
    }

    deleteSearch = async (e, funnel) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (await confirm({
            header: 'Delete Funnel',
            confirmButton: 'Delete',
            confirmation: `Are you sure you want to permanently delete "${funnel.name}"?`
        })) {
            this.props.deleteSearch(funnel.funnelId).then(function() {
                this.props.fetchFunnelsList();
                this.setState({ showSaveModal: false })
            }.bind(this));
        } else {}
    }

    createHandler = () => {
        const { events } = this.props;
        if (events.size === 0) {
            this.props.addEvent(Event({ type: TYPES.LOCATION, key: TYPES.LOCATION } ))
            this.props.addEvent(Event({ type: TYPES.LOCATION, key: TYPES.LOCATION } ))
            this.props.addEvent(Event({ type: TYPES.CLICK, key: TYPES.CLICK } ))
        } else {
            this.props.setBlink()
        }
    }

    onFlowClick = ({ funnelId }) => {
        const { siteId, history } = this.props; 
        history.push(withSiteId(funnelRoute(funnelId), siteId));
    }

    render() {
        const { funnels, activeFlow, activeTab, loading } = this.props;
        const { showMore, showSaveModal } = this.state;
        const shouldLimit = funnels.size > DEFAULT_VISIBLE;

        return (
            <div className={ stl.wrapper }>
               
            </div>
        );
    }
}

export default connect(state => ({
    funnels: state.getIn([ 'funnels', 'list' ]),
    loading: state.getIn(['funnels', 'fetchListRequest', 'loading']),
    activeFlow: state.getIn([ 'filters', 'activeFlow' ]),
    activeTab: state.getIn([ 'sessions', 'activeTab' ]),
    siteId: state.getIn([ 'user', 'siteId' ]),
    events: state.getIn([ 'filters', 'appliedFilter', 'events' ]),
}), { 
    applySavedFilter,
    deleteSearch, setActiveTab,
    setActiveFlow, clearEvents,
    addEvent,
    init,
    fetchFunnelsList,
    setBlink
})(SavedSearchList)
