var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { fetchLiveList } from 'Duck/sessions';
import { connect } from 'react-redux';
import { NoContent, Loader } from 'UI';
import SessionItem from 'Shared/SessionItem';
function LiveSessionList(props) {
    var loading = props.loading, list = props.list, filters = props.filters;
    var _a = __read(useState(undefined), 2), userId = _a[0], setUserId = _a[1];
    useEffect(function () {
        props.fetchLiveList();
    }, []);
    useEffect(function () {
        if (filters) {
            var userIdFilter = filters.filter(function (i) { return i.key === 'USERID'; }).first();
            if (userIdFilter)
                setUserId(userIdFilter.value[0]);
            else
                setUserId(undefined);
        }
    }, [filters]);
    return (React.createElement("div", null,
        React.createElement(NoContent, { title: "No live sessions!", subtext: "Please try changing your search parameters.", icon: "exclamation-circle", show: !loading && list && list.size === 0 },
            React.createElement(Loader, { loading: loading }, list && (userId ? list.filter(function (i) { return i.userId === userId; }) : list).map(function (session) { return (React.createElement(SessionItem, { key: session.sessionId, session: session, live: true })); })))));
}
export default connect(function (state) { return ({
    list: state.getIn(['sessions', 'liveSessions']),
    loading: state.getIn(['sessions', 'loading']),
    filters: state.getIn(['filters', 'appliedFilter', 'filters']),
}); }, { fetchLiveList: fetchLiveList })(LiveSessionList);
