import Record from 'Types/Record';

export default Record({
  time: undefined,
  index: undefined,
  action: {},
  state: undefined,
  diff: [],
  duration: undefined,
});


