import { Icon } from 'UI';
import cn from "classnames";
import stl from './errorName.css';

function ErrorText({ className, icon, name, message, bold, lineThrough = false ,trigger }) {
	return (
    <div className={ cn("mb-1 truncate", { "font-weight-bold": bold } , stl.truncate) }>
      { icon && <Icon name={icon} className="float-left mr-2" size="14" style={{ marginTop: '1px'}}/> }
      <span className={cn("code-font color-red", className, { 'line-through' : lineThrough })}>{ name }</span>
      <span className={cn('color-gray-darkest ml-2', { 'line-through' : lineThrough })}>{ message }</span>
      { trigger }
    </div>
	);
}

ErrorText.displayName = "ErrorText";

export default ErrorText;