import { connect } from 'react-redux';
import { Input, Button, Label } from 'UI';
import { save, edit, update , fetchList } from 'Duck/site';
import { pushNewSite, setSiteId } from 'Duck/user';
import styles from './siteForm.css';

@connect(state => ({
	site: state.getIn([ 'site', 'instance' ]),
	sites: state.getIn([ 'site', 'list' ]),
	siteList: state.getIn([ 'site', 'list' ]),
	loading: state.getIn([ 'site', 'save', 'loading' ]),
}), {
	save,
	edit,
	update,
	pushNewSite,
	fetchList,
  setSiteId
})
export default class NewSiteForm extends React.PureComponent {
	state = {
		existsError: false,
	}

	onSubmit = e => {
		e.preventDefault();
		const { site, siteList } = this.props;
		if (!site.exists() && siteList.some(({ name }) => name === site.name)) {
			return this.setState({ existsError: true });
		}
		if (site.exists()) {
			this.props.update(this.props.site, this.props.site.id).then(() => {
				this.props.onClose(null)
				this.props.fetchList();
			})
		} else {
			this.props.save(this.props.site).then(() => {
				const { sites } = this.props;        
        const site = sites.last();

        this.props.pushNewSite(site)
        this.props.setSiteId(site.id)
				this.props.onClose(null, site)
			});
		}
	}

	edit = ({ target: { name, value } }) => {
		if (value.includes(' ')) return;  // TODO: site validation
		this.setState({ existsError: false });
 		this.props.edit({ [ name ]: value });
 	}

	render() {
		const { site, loading, onClose } = this.props;
		return (
			<form className={ styles.formWrapper } onSubmit={ this.onSubmit }>
      	    </form>
	  );
	}
}