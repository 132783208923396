import { connect } from 'react-redux';
import withPageTitle from 'HOCs/withPageTitle';
import { Loader, IconButton, Button, Icon, SlideModal } from 'UI';
import { fetchList as fetchListSlack } from 'Duck/integrations/slack';
import { remove as removeIntegrationConfig } from 'Duck/integrations/actions';
import { fetchList, init } from 'Duck/integrations/actions';
import cn from 'classnames';

import IntegrationItem from './IntegrationItem';
import SentryForm from './SentryForm';
import GithubForm from './GithubForm';
import SlackForm from './SlackForm';
import DatadogForm from './DatadogForm';
import StackdriverForm from './StackdriverForm';
import RollbarForm from './RollbarForm';
import NewrelicForm from './NewrelicForm';
import BugsnagForm from './BugsnagForm';
import CloudwatchForm from './CloudwatchForm';
import ElasticsearchForm from './ElasticsearchForm';
import SumoLogicForm from './SumoLogicForm';
import JiraForm from './JiraForm';
import styles from './integrations.css';
import ReduxDoc from './ReduxDoc';
import VueDoc from './VueDoc';
import GraphQLDoc from './GraphQLDoc';
import NgRxDoc from './NgRxDoc/NgRxDoc';
import SlackAddForm from './SlackAddForm';
import FetchDoc from './FetchDoc';
import MobxDoc from './MobxDoc';
import ProfilerDoc from './ProfilerDoc';

const NONE = -1;
const SENTRY = 0;
const DATADOG = 1;
const STACKDRIVER = 2;
const ROLLBAR = 3;
const NEWRELIC = 4;
const BUGSNAG = 5;
const CLOUDWATCH = 6;
const ELASTICSEARCH = 7;
const SUMOLOGIC = 8;
const JIRA = 9;
const GITHUB = 10;
const REDUX = 11;
const VUE = 12;
const GRAPHQL = 13;
const NGRX = 14;
const SLACK = 15;
const FETCH = 16;
const MOBX = 17;
const PROFILER = 18;

const TITLE = {
	[ SENTRY ]: 'Sentry',
	[ SLACK ]: 'Slack',
	[ DATADOG ]: 'Datadog',
	[ STACKDRIVER ]: 'Stackdriver',
	[ ROLLBAR ]: 'Rollbar',
	[ NEWRELIC ]: 'New Relic',
	[ BUGSNAG ]: 'Bugsnag',
	[ CLOUDWATCH ]: 'CloudWatch',
	[ ELASTICSEARCH ]: 'Elastic Search',
	[ SUMOLOGIC ]: 'Sumo Logic',
	[ JIRA ]: 'Jira',
	[ GITHUB ]: 'Github',
	[ REDUX ] : 'Redux',
	[ VUE ] : 'VueX',
	[ GRAPHQL ] : 'GraphQL',
	[ NGRX ] : 'NgRx',
	[ FETCH ] : 'Fetch',
	[ MOBX ] : 'MobX',
	[ PROFILER ] : 'Profiler',
}

const DOCS = [REDUX, VUE, GRAPHQL, NGRX, FETCH, MOBX, PROFILER]

const integrations = [ 'sentry', 'datadog', 'stackdriver', 'rollbar', 'newrelic', 'bugsnag', 'cloudwatch', 'elasticsearch', 'sumologic', 'issues' ];

@connect(state => {
	const props = {};
	integrations.forEach(name => {
		props[ `${ name }Integrated`] = name === 'issues' ? 
			!!(state.getIn([ name, 'list' ]).first() && state.getIn([ name, 'list' ]).first().token) :
			state.getIn([ name, 'list' ]).size > 0;
		props.loading = props.loading || state.getIn([ name, 'fetchRequest', 'loading']);
	})
	return {
		...props,
		issues: state.getIn([ 'issues', 'list']).first() || {},
		slackChannelListExists: state.getIn([ 'slack', 'list' ]).size > 0,
		tenantId: state.getIn([ 'user', 'client', 'tenantId' ]),
		jwt: state.get('jwt')
	};
}, {
	fetchList,
	init,
	fetchListSlack,
	removeIntegrationConfig
})
@withPageTitle('Integrations - Stack Analytix Replay Preferences')
export default class Integrations extends React.PureComponent {
	state = { 
    modalContent: NONE,
		showDetailContent: false,
	};
	
	componentWillMount() {
		integrations.forEach(name => 
			this.props.fetchList(name)
		);
		this.props.fetchListSlack();
  }

	onClickIntegrationItem = (e, url) => {
    e.preventDefault();
    window.open(url);
	}

	closeModal = () => this.setState({ modalContent: NONE, showDetailContent: false });

	onOauthClick = (source) => {
		if (source === GITHUB) {
			const githubUrl = `https://auth.openreplay.com/oauth/login?provider=github&back_url=${document.location.href}`;
			const options = {
				method: 'GET',
				credentials: 'include',
				headers: new Headers({
					'Authorization': 'Bearer ' + this.props.jwt.toString()
				})
			};
			fetch(githubUrl, options).then((resp) => resp.text().then((txt) => window.open(txt, '_self')))
		}
	}

	renderDetailContent() {
		switch (this.state.modalContent) {
      case SLACK:
        return <SlackAddForm  onClose={ () => this.setState({ showDetailContent: false }) } />;
		}
	}

	renderModalContent() { 
    switch (this.state.modalContent) {
      case SENTRY:
        return <SentryForm onClose={ this.closeModal } />;
			case GITHUB:
				return <GithubForm onClose={ this.closeModal } />;
			case SLACK:
				return <SlackForm
					onClose={ this.closeModal }
					onEdit={() => this.setState({ showDetailContent: true })}
				/>
      case DATADOG:
      	return <DatadogForm onClose={ this.closeModal } />;
      case STACKDRIVER:
      	return <StackdriverForm onClose={ this.closeModal } />;
      case ROLLBAR: 
      	return <RollbarForm onClose={ this.closeModal } />;
      case NEWRELIC:
      	return <NewrelicForm onClose={ this.closeModal } />;
      case BUGSNAG: 
      	return <BugsnagForm onClose={ this.closeModal } />;
      case CLOUDWATCH:
      	return <CloudwatchForm onClose={ this.closeModal } />;
      case ELASTICSEARCH:
				return <ElasticsearchForm onClose={ this.closeModal } />;
			case SUMOLOGIC:
				return <SumoLogicForm onClose={ this.closeModal } />;
			case JIRA:
				return <JiraForm onClose={ this.closeModal } />;
			case REDUX:
				return <ReduxDoc onClose={ this.closeModal } />
			case VUE:
				return <VueDoc onClose={ this.closeModal } />
			case GRAPHQL:
				return <GraphQLDoc onClose={ this.closeModal } />
			case NGRX:
				return <NgRxDoc onClose={ this.closeModal } />
			case FETCH:
				return <FetchDoc onClose={ this.closeModal } />
			case MOBX:
				return <MobxDoc onClose={ this.closeModal } />
			case PROFILER:
				return <ProfilerDoc onClose={ this.closeModal } />
      default:
        return null;
    }
	}

	deleteHandler = name => {
    this.props.removeIntegrationConfig(name, null).then(function() {
			this.props.fetchList(name)
		}.bind(this));
	}
	
	showIntegrationConfig = (type) => {    
    this.setState({ modalContent: type });
  }

	render() {
		const { 
			loading, 
			sentryIntegrated, 
			stackdriverIntegrated,
			datadogIntegrated,
			rollbarIntegrated,
			newrelicIntegrated,
			bugsnagIntegrated,
			cloudwatchIntegrated,
			elasticsearchIntegrated,
			sumologicIntegrated,
			hideHeader=false,
			plugins=false,
			jiraIntegrated,
			issuesIntegrated,
			tenantId,
			slackChannelListExists,
			issues,
		} = this.props;
		const { modalContent, showDetailContent } = this.state;
		return (
			<div className={ styles.wrapper }>
				<SlideModal
          title={ 
						<div className="flex items-center">
							<div className="mr-4">{TITLE[ modalContent ]}</div>
							{ modalContent === SLACK && (
								<IconButton
									circle
									icon="plus"
									outline
									small="small"
									onClick={() => this.setState({ showDetailContent: true })}
								/>
							)}
						</div>
					}
          isDisplayed={ modalContent !== NONE }
          onClose={ this.closeModal }
					size={ DOCS.includes(this.state.modalContent)  ? 'middle' : 'small' }
					content={ this.renderModalContent() }
					detailContent={ showDetailContent && this.renderDetailContent() }
				/>

				

				

				
			</div>
	  );
	}
}