import withRequest from 'HOCs/withRequest';
import { toast } from 'react-toastify';
import * as React from 'react';
import { Popup } from 'UI';
import styles from './feedbackPopup.css';

@withRequest({ endpoint: ({ }, link) => `/integration/${link}`, method: "POST" })

export default class FeedbackPopup extends React.PureComponent {
  // to bind 
  /* <div className='feedbackPopup'>
    <FeedbackPopup
      trigger={
        <IconButton label="Feedback" primaryText />
      }
    />
  </div> */
  state = { 
    isOpen: false,
   };
  
  handleSuccess = (msg) => {
    toast.success(msg);
    this.handleClose();
  }

  showMailContent = () => {
    this.setState({ sendMail:true });
  }
  sendToMail = () => 
  this.props.request({
  }, ` `).then(this.handleSuccess('Mail is Sent.'))

  handleOpen = () => {
    this.setState({  isOpen: true});
  }

  handleClose = () => {
    this.setState({ isOpen: false});
  }


  render() {
    const { trigger, loading } = this.props;
    const { isOpen } = this.state;

    return (
      <Popup
        open={isOpen}
        onOpen={this.handleOpen}
        // onClose={this.handleClose}
        trigger={trigger}
        content={
          <div className={styles.wrapper}>
             <div 
                className={styles.closeBtn}
                onClick={this.handleClose}>
            </div>
            <div className={styles.emojis}>
              <div className={styles.emojis_btn}>
                <button type="button" >😍</button>
              </div>
              <div className={styles.emojis_btn}>
                <button type="button" >🙂</button>
              </div>
              <div className={styles.emojis_btn}>
                <button type="button" >🙁</button>
              </div>
              <div className={styles.emojis_btn}>
                <button type="button">😭</button>
              </div>
            </div>
           <div className={styles.content}>
            <div className={styles.header}>
                <div className={styles.title}>{'Comment'}</div>
              </div>
              <div className={styles.body}>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows='6'
                  resize="none"
                  // onChange={this.editMessage}
                  // value={comment}
                  placeholder="Type here..."
                  className="p-4"
                />
              </div>
           </div>
          </div>     
        }
        on="click"
        position="top right"
        className={styles.popup}
      />
    );
  }
}
