import store from 'App/store';

import { queried } from './routes';

const siteIdRequiredPaths = [
  '/dashboard',
  '/sessions',
  '/events',
  '/filters',
  '/alerts',
  '/targets',
  '/metadata',
  '/integrations/sentry/events',
  '/integrations/slack/notify',
  '/assignments',
  '/integration/sources',
  '/issue_types',
  '/sample_rate',
  '/flows',
  '/rehydrations',
  '/sourcemaps',
  '/errors',
  '/funnels',
  '/assist'
];

const noStoringFetchPathStarts = [
  '/account/password',
  '/password',
  '/login'
];

// null?
export const clean = (obj, forbidenValues = [ undefined, '' ])  => {
  const keys = Array.isArray(obj) 
    ? new Array(obj.length).fill().map((_, i) => i)
    : Object.keys(obj);
  const retObj = Array.isArray(obj) ? [] : {};
  keys.map(key => {
    const value = obj[key];
    if (typeof value === 'object' && value !== null) {
      retObj[key] = clean(value);
    } else if (!forbidenValues.includes(value)) {
      retObj[key] = value;
    }
  });
 
  return retObj;
}


export default class APIClient {
  constructor() {
    const jwtAll = store.getState().get('jwt');

    if(jwtAll != null){
      const jwt = jwtAll.split('%_END')[1];
      this.stkJWT = jwtAll.split('%_END')[1];

    const siteId = store.getState().getIn([ 'user', 'siteId' ]);
    this.init = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    if (jwt !== null) {
      this.init.headers.Authorization = `Bearer ${ jwt }`;
    }
    this.siteId = siteId;

    }else{
      const jwt = jwtAll;
      this.stkJWT = jwtAll;

      const siteId = store.getState().getIn([ 'user', 'siteId' ]);
    this.init = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    if (jwt !== null) {
      this.init.headers.Authorization = `Bearer ${ jwt }`;
    }
    this.siteId = siteId;
    }
  }

  fetch(path, params, options = { clean: true }) {
    if (params !== undefined) {
      const cleanedParams = options.clean ? clean(params) : params;
      this.init.body = JSON.stringify(cleanedParams);
    }

    
    let fetch = window.fetch;
    let edp = 'https://api.qandel.io/v2/api/STKCore';
    if (
      path !== '/targets_temp' &&
      !path.includes('/metadata/session_search') &&
      !path.includes('/watchdogs/rules') &&
      !!this.siteId &&
      siteIdRequiredPaths.some(sidPath => path.startsWith(sidPath))
    ) {
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      edp = `${ edp }/${ this.siteId }`
    }

    if(path.includes('/sessions2/') && path.includes('favorite')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch(edp + path, this.init);
    }

    if(path.includes('/errors/stats')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch('https://api.qandel.io/v2/api/Replay/errors', this.init);
    }
    

    if(path.includes('errors/search')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch('https://api.qandel.io/v2/api/Replay/ErrorSearch/' + this.siteId, this.init);
    }


    if(path.includes('sessions/search2')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch('https://api.qandel.io/v2/api/Replay/SessionSearch/' + this.siteId, this.init);
    }

    if(path.includes('/sessions2/')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      let sessionId = path.split('/sessions2/')[1];
      return fetch('https://api.qandel.io/v2/api/Replay/EventsSearch/' + this.siteId + '/' + sessionId, this.init);
    }

    if(path.includes('notifications')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch('https://api.qandel.io/v2/api/Replay/notifications', this.init);
    }

    if(path.includes('boarding')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch('https://api.qandel.io/v2/api/Replay/boarding', this.init);
    }
    
    if(path.includes('metadata')){
      if(path.includes('metadata/session_search')){
        return fetch(edp + path + "&projectId=" + this.siteId, this.init);
      }else{
        this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
        return fetch('https://api.qandel.io/v2/api/Replay/metadata/' + this.siteId, this.init);
      }
      
    }

    if(path.includes('account')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      if(this.init.method != 'POST'){
        return fetch('https://api.qandel.io/v2/api/Replay/account', this.init);
      }else{
        return fetch('https://api.qandel.io/v2/api/Replay/PostaAccount', this.init);
      }
    }

    if(path.includes('sample_rate')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      if(this.init.method != 'POST'){
        return fetch('https://api.qandel.io/v2/api/Replay/sample_rate/' + this.siteId , this.init);
      }else{
        return fetch('https://api.qandel.io/v2/api/Replay/change_sample_rate/' + this.siteId , this.init);
      }
    }

    if(path.includes('signup')){
      if(localStorage.getItem("sessionkey")){
        if (localStorage.justOnce == undefined || localStorage.justOnce == true) {
          localStorage.setItem("justOnce", "true");
          window.location.reload();
        }
      }
      this.init.headers.Authorization = `Bearer ${ localStorage.getItem("sessionkey") }`;
      return fetch('https://api.qandel.io/v2/api/Replay/signup', this.init);
    }

    if(path.includes('client')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch('https://api.qandel.io/v2/api/Replay/client', this.init);
    }

    if(path.includes('funnels')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch('https://api.qandel.io/v2/api/Replay/funnels', this.init);
    }

    // /integrations/slack/channels
    // /integrations/issues
    // /integrations/slack/notify/undefined/sessions/undefined   POST
    if(path.includes('integration')){
        this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
        return fetch(`https://api.qandel.io/v2/api${path}`, this.init).then(res => {
          res.json().then(res => {localStorage.setItem("url", res.url);});
      })
    }

    if(path.includes('announcements')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch('https://api.qandel.io/v2/api/Replay/announcements', this.init);
    }

    if(path.includes('/events/search')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      let query = path.split('?type')[1];
      return fetch('https://api.qandel.io/v2/api/Replay/EventSearch/' + this.siteId + '?type' + query , this.init);
    }

    if(path.includes('/projects')){
      this.init.headers.Authorization = `Bearer ${ this.stkJWT }`;
      return fetch('https://api.qandel.io/v2/api/Replay/GetProjects', this.init);
    }

    if(path == '/login' || path === '/login'){
      return fetch('https://api.qandel.io/v2/api/Account/Login', this.init);
    }
    else{
      return fetch(edp + path, this.init);
    }
  }

  get(path, params, options) {
    this.init.method = 'GET';
    return this.fetch(queried(path, params, options));
  }

  post(path, params, options) {
    this.init.method = 'POST';
    return this.fetch(path, params);
  }

  put(path, params, options) {
    this.init.method = 'PUT';
    return this.fetch(path, params);
  }

  delete(path, params, options) {
    this.init.method = 'DELETE';
    return this.fetch(path, params);
  }
}
