var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ID_TP_MAP } from '../messages';
import store from 'App/store';
import { update, getState } from '../../store';
export var CallingState;
(function (CallingState) {
    CallingState[CallingState["Requesting"] = 0] = "Requesting";
    CallingState[CallingState["True"] = 1] = "True";
    CallingState[CallingState["False"] = 2] = "False";
})(CallingState || (CallingState = {}));
;
export var ConnectionStatus;
(function (ConnectionStatus) {
    ConnectionStatus[ConnectionStatus["Connecting"] = 0] = "Connecting";
    ConnectionStatus[ConnectionStatus["Connected"] = 1] = "Connected";
    ConnectionStatus[ConnectionStatus["Inactive"] = 2] = "Inactive";
    ConnectionStatus[ConnectionStatus["Disconnected"] = 3] = "Disconnected";
    ConnectionStatus[ConnectionStatus["Error"] = 4] = "Error";
})(ConnectionStatus || (ConnectionStatus = {}));
;
export function getStatusText(status) {
    switch (status) {
        case ConnectionStatus.Connecting:
            return "Connecting...";
        case ConnectionStatus.Connected:
            return "";
        case ConnectionStatus.Inactive:
            return "Client tab is inactive";
        case ConnectionStatus.Disconnected:
            return "Disconnected";
        case ConnectionStatus.Error:
            return "Something went wrong. Try to reload the page.";
    }
}
export var INITIAL_STATE = {
    calling: CallingState.False,
    peerConnectionStatus: ConnectionStatus.Connecting,
};
var MAX_RECONNECTION_COUNT = 4;
function resolveURL(baseURL, relURL) {
    if (relURL.startsWith('#') || relURL === "") {
        return relURL;
    }
    return new URL(relURL, baseURL).toString();
}
var match = /bar/.exec("foobar");
var re1 = /url\(("[^"]*"|'[^']*'|[^)]*)\)/g;
var re2 = /@import "(.*?)"/g;
function cssUrlsIndex(css) {
    var e_1, _a, e_2, _b;
    var idxs = [];
    var i1 = css.matchAll(re1);
    try {
        // @ts-ignore
        for (var i1_1 = __values(i1), i1_1_1 = i1_1.next(); !i1_1_1.done; i1_1_1 = i1_1.next()) {
            var m = i1_1_1.value;
            // @ts-ignore
            var s = m.index + m[0].indexOf(m[1]);
            var e = s + m[1].length;
            idxs.push([s, e]);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (i1_1_1 && !i1_1_1.done && (_a = i1_1.return)) _a.call(i1_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var i2 = css.matchAll(re2);
    try {
        // @ts-ignore
        for (var i2_1 = __values(i2), i2_1_1 = i2_1.next(); !i2_1_1.done; i2_1_1 = i2_1.next()) {
            var m = i2_1_1.value;
            // @ts-ignore
            var s = m.index + m[0].indexOf(m[1]);
            var e = s + m[1].length;
            idxs.push([s, e]);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (i2_1_1 && !i2_1_1.done && (_b = i2_1.return)) _b.call(i2_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return idxs;
}
function unquote(str) {
    str = str.trim();
    if (str.length <= 2) {
        return [str, ""];
    }
    if (str[0] == '"' && str[str.length - 1] == '"') {
        return [str.substring(1, str.length - 1), "\""];
    }
    if (str[0] == '\'' && str[str.length - 1] == '\'') {
        return [str.substring(1, str.length - 1), "'"];
    }
    return [str, ""];
}
function rewriteCSSLinks(css, rewriter) {
    var e_3, _a;
    try {
        for (var _b = __values(cssUrlsIndex(css)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var idx = _c.value;
            var f = idx[0];
            var t = idx[1];
            var _d = __read(unquote(css.substring(f, t)), 2), rawurl = _d[0], q = _d[1];
            css = css.substring(0, f) + q + rewriter(rawurl) + q + css.substring(t);
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return css;
}
function resolveCSS(baseURL, css) {
    return rewriteCSSLinks(css, function (rawurl) { return resolveURL(baseURL, rawurl); });
}
var AssistManager = /** @class */ (function () {
    function AssistManager(session, md) {
        var _this = this;
        this.session = session;
        this.md = md;
        this.peer = null;
        this.connectionAttempts = 0;
        this.peeropened = false;
        this.onCallEnd = null;
        this.onReject = null;
        this.initiateCallEnd = function () {
            var _a;
            console.log('initiateCallEnd');
            _this.forceCallEnd();
            _this.notifyCallEnd();
            (_a = _this.onCallEnd) === null || _a === void 0 ? void 0 : _a.call(_this);
        };
        this.onTrackerCallEnd = function () {
            var _a, _b;
            _this.forceCallEnd();
            if (getState().calling === CallingState.Requesting) {
                (_a = _this.onReject) === null || _a === void 0 ? void 0 : _a.call(_this);
            }
            (_b = _this.onCallEnd) === null || _b === void 0 ? void 0 : _b.call(_this);
        };
        this.mesagesRecieved = false;
        this.onMouseMove = function (e) {
            var conn = _this.dataConnection;
            if (!conn) {
                return;
            }
            // @ts-ignore ???
            var data = _this.md.getInternalCoordinates(e);
            conn.send({ x: Math.round(data.x), y: Math.round(data.y) });
        };
    }
    Object.defineProperty(AssistManager.prototype, "peerID", {
        get: function () {
            return this.session.projectKey + "-" + this.session.sessionId;
        },
        enumerable: false,
        configurable: true
    });
    AssistManager.prototype.connect = function () {
        var _this = this;
        if (this.peer != null) {
            console.error("AssistManager: trying to connect more than once");
            return;
        }
        this.md.setMessagesLoading(true);
        import('peerjs').then(function (_a) {
            var Peer = _a.default;
            // @ts-ignore
            var peer = new Peer({
                // @ts-ignore
                host: new URL(window.ENV.API_EDP).host,
                path: '/assist',
                port: location.protocol === 'https:' ? 443 : 80,
            });
            _this.peer = peer;
            peer.on('error', function (e) {
                if (['peer-unavailable', 'network'].includes(e.type)) {
                    if (_this.peer && _this.connectionAttempts++ < MAX_RECONNECTION_COUNT) {
                        update({ peerConnectionStatus: ConnectionStatus.Connecting });
                        console.log("peerunavailable");
                        _this.connectToPeer();
                    }
                    else {
                        update({ peerConnectionStatus: ConnectionStatus.Disconnected });
                        _this.dataCheckIntervalID && clearInterval(_this.dataCheckIntervalID);
                    }
                }
                else {
                    console.error("PeerJS error (on peer). Type " + e.type, e);
                    update({ peerConnectionStatus: ConnectionStatus.Error });
                }
            });
            peer.on("open", function () {
                if (_this.peeropened) {
                    return;
                }
                _this.peeropened = true;
                console.log('peeropen');
                _this.connectToPeer();
            });
        });
    };
    AssistManager.prototype.connectToPeer = function () {
        var _this = this;
        if (!this.peer) {
            return;
        }
        update({ peerConnectionStatus: ConnectionStatus.Connecting });
        var id = this.peerID;
        console.log("trying to connect to", id);
        var conn = this.peer.connect(id, { serialization: 'json', reliable: true });
        conn.on('open', function () {
            window.addEventListener("beforeunload", function () { return conn.open && conn.send("unload"); });
            console.log("peer connected");
            var i = 0;
            var firstMessage = true;
            update({ peerConnectionStatus: ConnectionStatus.Connected });
            conn.on('data', function (data) {
                if (!Array.isArray(data)) {
                    return _this.handleCommand(data);
                }
                _this.mesagesRecieved = true;
                if (firstMessage) {
                    firstMessage = false;
                    _this.md.setMessagesLoading(false);
                }
                var time = 0;
                var ts0 = 0;
                data.forEach(function (msg) {
                    // TODO: more appropriate way to do it.
                    if (msg._id === 60) {
                        // @ts-ignore
                        if (msg.name === 'src' || msg.name === 'href') {
                            // @ts-ignore
                            msg.value = resolveURL(msg.baseURL, msg.value);
                            // @ts-ignore
                        }
                        else if (msg.name === 'style') {
                            // @ts-ignore
                            msg.value = resolveCSS(msg.baseURL, msg.value);
                        }
                        msg._id = 12;
                    }
                    else if (msg._id === 61) { // "SetCSSDataURLBased"
                        // @ts-ignore
                        msg.data = resolveCSS(msg.baseURL, msg.data);
                        msg._id = 15;
                    }
                    else if (msg._id === 67) { // "insert_rule"
                        // @ts-ignore
                        msg.rule = resolveCSS(msg.baseURL, msg.rule);
                        msg._id = 37;
                    }
                    msg.tp = ID_TP_MAP[msg._id]; // _id goes from tracker
                    if (msg.tp === "timestamp") {
                        ts0 = ts0 || msg.timestamp;
                        time = msg.timestamp - ts0;
                        return;
                    }
                    var tMsg = Object.assign(msg, {
                        time: time,
                        _index: i,
                    });
                    _this.md.distributeMessage(tMsg, i++);
                });
            });
        });
        var onDataClose = function () {
            _this.initiateCallEnd();
            _this.md.setMessagesLoading(true);
            update({ peerConnectionStatus: ConnectionStatus.Connecting });
            console.log('closed peer conn. Reconnecting...');
            _this.connectToPeer();
        };
        // this.dataCheckIntervalID = setInterval(() => {
        //   if (!this.dataConnection && getState().peerConnectionStatus === ConnectionStatus.Connected) {
        //     onDataClose();
        //   }
        // }, 3000);
        conn.on('close', onDataClose); // Does it work ?
        conn.on("error", function (e) {
            console.log("PeerJS connection error", e);
            update({ peerConnectionStatus: ConnectionStatus.Error });
        });
    };
    Object.defineProperty(AssistManager.prototype, "dataConnection", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.peer) === null || _a === void 0 ? void 0 : _a.connections[this.peerID]) === null || _b === void 0 ? void 0 : _b.find(function (c) { return c.type === 'data' && c.open; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AssistManager.prototype, "callConnection", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.peer) === null || _a === void 0 ? void 0 : _a.connections[this.peerID]) === null || _b === void 0 ? void 0 : _b.find(function (c) { return c.type === 'media' && c.open; });
        },
        enumerable: false,
        configurable: true
    });
    AssistManager.prototype.send = function (data) {
        var _a;
        (_a = this.dataConnection) === null || _a === void 0 ? void 0 : _a.send(data);
    };
    AssistManager.prototype.forceCallEnd = function () {
        var _a;
        (_a = this.callConnection) === null || _a === void 0 ? void 0 : _a.close();
    };
    AssistManager.prototype.notifyCallEnd = function () {
        var dataConn = this.dataConnection;
        if (dataConn) {
            console.log("notifyCallEnd send");
            dataConn.send("call_end");
        }
    };
    AssistManager.prototype.handleCommand = function (command) {
        var _this = this;
        switch (command) {
            case "unload":
                this.onTrackerCallEnd();
                this.mesagesRecieved = false;
                setTimeout(function () {
                    var _a;
                    if (_this.mesagesRecieved) {
                        return;
                    }
                    // @ts-ignore
                    _this.md.display(false);
                    (_a = _this.dataConnection) === null || _a === void 0 ? void 0 : _a.close();
                    update({ peerConnectionStatus: ConnectionStatus.Disconnected });
                }, 8000); // TODO: more convenient way
                //this.dataConnection?.close();
                return;
            case "call_end":
                this.onTrackerCallEnd();
                return;
            case "call_error":
                this.onTrackerCallEnd();
                update({ peerConnectionStatus: ConnectionStatus.Error });
                return;
        }
    };
    AssistManager.prototype.call = function (localStream, onStream, onCallEnd, onReject, onError) {
        var _this = this;
        if (!this.peer || getState().calling !== CallingState.False) {
            return null;
        }
        update({ calling: CallingState.Requesting });
        console.log('calling...');
        var call = this.peer.call(this.peerID, localStream);
        call.on('stream', function (stream) {
            //call.peerConnection.ontrack = (t)=> console.log('ontrack', t)
            update({ calling: CallingState.True });
            onStream(stream);
            _this.send({
                name: store.getState().getIn(['user', 'account', 'name']),
            });
            // @ts-ignore ??
            _this.md.overlay.addEventListener("mousemove", _this.onMouseMove);
        });
        this.onCallEnd = function () {
            onCallEnd();
            // @ts-ignore ??
            _this.md.overlay.removeEventListener("mousemove", _this.onMouseMove);
            update({ calling: CallingState.False });
            _this.onCallEnd = null;
        };
        call.on("close", this.onCallEnd);
        call.on("error", function (e) {
            var _a;
            console.error("PeerJS error (on call):", e);
            (_a = _this.initiateCallEnd) === null || _a === void 0 ? void 0 : _a.call(_this);
            onError === null || onError === void 0 ? void 0 : onError();
        });
        // const intervalID = setInterval(() => {
        //   if (!call.open && getState().calling === CallingState.True) {
        //     this.onCallEnd?.();
        //     clearInterval(intervalID);
        //   }
        // }, 5000);
        window.addEventListener("beforeunload", this.initiateCallEnd);
        return this.initiateCallEnd;
    };
    AssistManager.prototype.clear = function () {
        var _a;
        console.log('clearing', this.peerID);
        this.initiateCallEnd();
        this.dataCheckIntervalID && clearInterval(this.dataCheckIntervalID);
        if (this.peer) {
            (_a = this.peer.connections[this.peerID]) === null || _a === void 0 ? void 0 : _a.forEach(function (c) { return c.open && c.close(); });
            console.log("destroying peer...");
            this.peer.disconnect();
            this.peer.destroy();
            this.peer = null;
        }
    };
    return AssistManager;
}());
export default AssistManager;
