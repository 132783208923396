var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import stl from './ChatControls.css';
import cn from 'classnames';
import { Button, Icon } from 'UI';
function ChatControls(_a) {
    var _b, _c;
    var stream = _a.stream, endCall = _a.endCall;
    var _d = __read(useState(true), 2), audioEnabled = _d[0], setAudioEnabled = _d[1];
    var _e = __read(useState(true), 2), videoEnabled = _e[0], setVideoEnabled = _e[1];
    var toggleAudio = function () {
        if (!stream) {
            return;
        }
        var aEn = !audioEnabled;
        stream.getAudioTracks().forEach(function (track) { return track.enabled = aEn; });
        setAudioEnabled(aEn);
    };
    var toggleVideo = function () {
        if (!stream) {
            return;
        }
        var vEn = !videoEnabled;
        stream.getVideoTracks().forEach(function (track) { return track.enabled = vEn; });
        setVideoEnabled(vEn);
    };
    return (React.createElement("div", { className: cn(stl.controls, "flex items-center w-full justify-start bottom-0 px-2") },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: cn(stl.btnWrapper, (_b = {}, _b[stl.disabled] = !audioEnabled, _b)) },
                React.createElement(Button, { plain: true, size: "small", onClick: toggleAudio, noPadding: true, className: "flex items-center" },
                    React.createElement(Icon, { name: audioEnabled ? 'mic' : 'mic-mute', size: "16" }),
                    React.createElement("span", { className: "ml-2 color-gray-medium text-sm" }, audioEnabled ? 'Mute' : 'Unmute'))),
            React.createElement("div", { className: cn(stl.btnWrapper, (_c = {}, _c[stl.disabled] = !videoEnabled, _c)) },
                React.createElement(Button, { plain: true, size: "small", onClick: toggleVideo, noPadding: true, className: "flex items-center" },
                    React.createElement(Icon, { name: videoEnabled ? 'camera-video' : 'camera-video-off', size: "16" }),
                    React.createElement("span", { className: "ml-2 color-gray-medium text-sm" }, videoEnabled ? 'Stop Video' : 'Start Video')))),
        React.createElement("div", { className: "ml-auto" },
            React.createElement("button", { className: stl.endButton, onClick: endCall }, "END"))));
}
export default ChatControls;
