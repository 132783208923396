import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import cn from 'classnames';
import { 
  sessions,
  client,
  errors,
  dashboard,
  withSiteId,
  CLIENT_DEFAULT_TAB,
  isRoute,
} from 'App/routes';
import { logout } from 'Duck/user';
import { Icon, Popup } from 'UI';
import SiteDropdown from './SiteDropdown';
import styles from './header.css';
import Discover from './Discover/Discover';
import OnboardingExplore from './OnboardingExplore/OnboardingExplore'
import Announcements from '../Announcements';
import Notifications from '../Alerts/Notifications';
import { init as initSite, fetchList as fetchSiteList } from 'Duck/site';

import ErrorGenPanel from 'App/dev/components';
import ErrorsBadge from 'Shared/ErrorsBadge';
import Alerts from '../Alerts/Alerts';
import AccountPopup from './AccountPopup';
import { toggle } from '../../duck/user';

const DASHBOARD_PATH = dashboard();
const SESSIONS_PATH = sessions();
const ERRORS_PATH = errors();
const CLIENT_PATH = client(CLIENT_DEFAULT_TAB);
const AUTOREFRESH_INTERVAL = 30 * 1000;

let interval = null;

const Header = (props) => {
  const { 
    sites, location, account,
    onLogoutClick, siteId, onToggleClick,
    boardingCompletion = 100, fetchSiteList, showAlerts = false
  } = props;
  
  const name = account.get('name');
  const email = account.get('email');
  const [hideDiscover, setHideDiscover] = useState(false)
  let activeSite = null;

  useEffect(() => {
    activeSite = sites.find(s => s.id == siteId);
    props.initSite(activeSite);
  }, [sites])
  
  const showTrackingModal = (
    isRoute(SESSIONS_PATH, location.pathname) ||     
    isRoute(ERRORS_PATH, location.pathname)
  ) && activeSite && !activeSite.recorded;

  useEffect(() => {
    if(showTrackingModal) {
      interval = setInterval(() => {
        fetchSiteList()
      }, AUTOREFRESH_INTERVAL);
    } else if (interval){
      clearInterval(interval);
    }
  }, [showTrackingModal])

  useEffect(() => {
    fetchSiteList()
  }, [])

  return (
    <div className={ cn(styles.header, showTrackingModal ? styles.placeOnTop : '' , 'header') }>
      <NavLink to={ withSiteId(SESSIONS_PATH, siteId) }>
        <div className="relative">
          <div className={ styles.logo } />
          <div className="absolute bottom-0" style={{ fontSize: '7px', right: '5px' }}>v{window.ENV.VERSION}</div>
        </div>
      </NavLink>
      <SiteDropdown />
      <div className={cn(styles.divider , 'header_divider') }  />

      <a class="header_nav_1rhxP6w" href="https://app.qandel.io/home"><span>HOME</span></a>
      <NavLink
        to={ withSiteId(SESSIONS_PATH, siteId) }
        className={ styles.nav }
        activeClassName={ styles.active }
      >
        { 'Sessions' }
      </NavLink>

      <a class="header_nav_1rhxP6w" href="https://app.qandel.io/app/heatmaps"><span>HEATMAPS</span></a>

      <NavLink
        to={ withSiteId(ERRORS_PATH, siteId) }
        className={ styles.nav }
        activeClassName={ styles.active }
      >
        <ErrorsBadge />
        { 'Errors' }
      </NavLink>
      <NavLink
        to={ withSiteId(DASHBOARD_PATH, siteId) }
        className={ styles.nav }
        activeClassName={ styles.active }
      >         
        <span>{ 'Metrics' }</span>
      </NavLink>
      <a class="header_nav_1rhxP6w" href="https://app.qandel.io/app/eventCenter"><span>EVENTS</span></a>
      <a class="header_nav_1rhxP6w" href="https://app.qandel.io/Dashboard/Analytics"><span>ANALYTICS</span></a>
      <a class="header_nav_1rhxP6w" href="https://app.qandel.io/app/goals"><span>GOALS</span></a>

      <div className={ styles.right }>
        <div className={ cn(styles.userDetails ,'header_userDetails' ) }>
          <div className="flex items-center">
            <div className="mr-5">{ name }</div>
            <Icon color="gray-medium" name="ellipsis-v" size="24" />
          </div>

          <AccountPopup onLogoutClick={onLogoutClick} onToggleClick={onToggleClick} name={name} email={email}/>
        </div>
      </div>
      { <ErrorGenPanel/> }
      {showAlerts && <Alerts />}
    </div>
  );
};

export default withRouter(connect(
  state => ({
    account: state.getIn([ 'user', 'account' ]),
    appearance: state.getIn([ 'user', 'account', 'appearance' ]),
    siteId: state.getIn([ 'user', 'siteId' ]),
    sites: state.getIn([ 'site', 'list' ]),
    showAlerts: state.getIn([ 'dashboard', 'showAlerts' ]),
    boardingCompletion: state.getIn([ 'dashboard', 'boardingCompletion' ])
  }),
  { onLogoutClick: logout, onToggleClick:toggle , initSite, fetchSiteList },
)(Header));
