import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import withRequest from 'HOCs/withRequest';
import { Popup, Icon, Input, Tooltip, Form, Checkbox } from 'UI';
import { pause } from 'Player';
import styles from './sharePopup.css';
import Select from 'react-select'
import cn from 'classnames';

@connect(state => ({
  channels: state.getIn(['slack', 'list']),
  tenantId: state.getIn(['user', 'tenantId']),
}))

@withRequest({ endpoint: ({ }, link) => `/integration/${link}`, method: "POST" })

export default class SharePopup extends React.PureComponent {
  state = {
    comment: '',
    duration: '12Hours',

    isOpen: false,
    slackChannels: [],
    ClickupGetData: [],
    ClickupMembers: [],

    JiraIssue: [],
    jiraProjectId: '',
    jiraProjectKey: '',
    issueType: '',
    jiraAssignees: '',
    JiraMembers: [],
    summary: '',
    error: '',
    taskName: '',
    attendeeEmails: [],
    formValues: [""],
    startDateTime: Date.now(),
    endDateTime: Date.now(),
    dueDate: Date.now(),
    clickupReady: false,
    JiraReady: false,
    assignees: [],
    integration: [{ clickup: true }, { jira: true }, { slack: true }, { zapier: false }],

    listId: '',
    projectId: window.location.href.split("/")[3],
    channelId: '',
    ddShow: true,
    channelShow: true,
    inputShow: true,
    inputUrlShow: true,
    onClickFun: '',
    shareName: '',
    emailTo: '',
    subject: '',
    shareBtn: false,
    shareLinks: true,
    selectTeam: false,
    seeFolder: false,
    createOne: false,
    url: '',
    checkBoxVal: '',
    spaceId: '',
    selectName: 'Team',
    addOneName: '',
  }

  onMailChange = ({ target: { value } }) => {
    this.setState({ emailTo: value });
  };
  onSubjectChange = ({ target: { value } }) => {
    this.setState({ subject: value });
  };

  onTaskNameChange = ({ target: { value } }) => {
    this.setState({ taskName: value });
  };

  onDueDateChange = ({ target: { value } }) => {
    this.setState({ dueDate: value });
  };


  onstartDateTimeChange = ({ target: { value } }) => {
    this.setState({ startDateTime: value });
    this.setState({ endDateTime: value + 3600000 });
  };

  onendDateTimeChange = ({ target: { value } }) => {
    this.setState({ endDateTime: value });
  };


  onFolderNChange = ({ target: { value } }) => {
    this.setState({ addOneName: value });
  };
  createFolder = async () =>
    this.props.request({},
      `ClickupFolderCreate/${this.state.projectId}?name=${this.state.addOneName}&spaceId=${this.state.spaceId}`)
      .then(this.folderList)

  createFolderList = async () =>
    this.props.request({},
      `ClickupFolderListCreate/${this.state.projectId}?name=${this.state.addOneName}&folderId=${this.state.checkBoxVal}`)
      .then(this.onSelectFolder)

  createList = async () =>
    this.props.request({},
      `ClickupListCreate/${this.state.projectId}?name=${this.state.addOneName}&spaceId=${this.state.spaceId}`)
      .then(this.onSelectSpace)

  onAssigneesChange = (e) => {
    var selected = [];
    e.forEach(assignees => { selected.push(assignees.value) });
    this.setState({ assignees: selected })
  };

  onJiraAssigneesChange = (e) => { this.setState({ jiraAssignees: e.value }) };

  onIssuesChange = (e) => { this.setState({ issueType: e.value }) };

  folderList = async () => {
    this.setState({ seeFolder: true, ClickupGetData: [], selectName: 'Folder' });
    const response = await fetch('https://api.qandel.io/v2/api/Integration/ClickupGet/' + this.state.projectId + '?path=folder&id=' + this.state.spaceId, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });
    const responseData = await response.json();
    this.setState({ ClickupGetData: responseData.folders, addOneName: '' });
  }

  editMessage = e => this.setState({ comment: e.target.value })

  editSummary = e => this.setState({ summary: e.target.value })

  Integrations = async () => {
    const response = await fetch('https://api.qandel.io/v2/api/Integration/Available/' + this.state.projectId, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });
    const responseData = await response.json();
    this.setState({ integration: responseData });
  }

  ClickupGet = async () => {
    this.showSelectTeam();
    const response = await fetch('https://api.qandel.io/v2/api/Integration/ClickupGet/' + this.state.projectId + '?path=team', {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });
    const responseData = await response.json();
    this.setState({ ClickupGetData: responseData.teams });
  }

  JiraProjectGet = async () => {
    this.showSelectProject();
    const response = await fetch('https://api.qandel.io/v2/api/Integration/JiraProjects/' + this.state.projectId, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });
    const responseData = await response.json();

    this.setState({ ClickupGetData: responseData });
  }

  onSelectIssues = async () => {
    const response = await fetch('https://api.qandel.io/v2/api/Integration/JiraProjectIssuesTypes/' + this.state.projectId + '?id=' + this.state.jiraProjectId, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });

    const responseData = await response.json();
    var issueList = [];
    responseData.forEach(element => {
      issueList.push({ label: element.name, value: element.id })
    });
    this.setState({ JiraIssue: issueList });
  }

  jiraProjectMembers = async () => {
    const response = await fetch('https://api.qandel.io/v2/api/Integration/JiraProjectMembers/' + this.state.projectId + '?key=' + this.state.jiraProjectKey, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });

    const responseData = await response.json();
    var members = [];
    responseData.forEach(element => {
      members.push({ label: element.displayName, value: element.accountId })
    });
    this.setState({ JiraMembers: members, seeFolder: false });
  }

  removeFormFields = (i) => {
    let formValue = this.state.attendeeEmails;
    formValue.splice(i, 1);
    this.setState(({
      formValues: [...formValue, ""],
    }))
    var filtered = this.state.attendeeEmails.filter(function (el) {
      return el !== "";
    });
    this.setState(({
      attendeeEmails: [...filtered, ""]
    }))
    console.log("attendeeEmails", this.state.attendeeEmails);
  }

  handleEmailSubmit = () => {
    var emailTo = document.getElementsByName('emailTo')[0].value;
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailTo.match(validRegex)) {
      this.setState({ error: "" });
      if (this.state.attendeeEmails.includes(emailTo)) {
        this.setState({ error: "This Email already exists" });
      }
      else {
        this.state.attendeeEmails.push(emailTo);

        //add email to formValue
        this.setState(({
          formValues: this.state.attendeeEmails,
        }));
        document.getElementsByName('emailTo')[0].value = "";
      }
    }

    console.log("attendeeEmails", this.state.attendeeEmails);
  }


  onSelectJiraProject = async () => {
    this.jiraProjectMembers();
    this.onSelectIssues();
    this.showJiraContent();
  }

  onChangeOption = (e, { checked, name, id }) => {
    if (this.state.selectName == 'Space') this.setState({ spaceId: id });
    if (this.state.selectName == 'Project') this.setState({ jiraProjectId: id, jiraProjectKey: name });
    this.setState({ checkBoxVal: id });
  }

  showSlackContent = () => {
    var channelsOptions = [];
    const fetchData = async () => {
      const response = await fetch('https://api.qandel.io/v2/api/Integration/SlackChannels/' + this.state.projectId, {
        headers: {
          'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
        }
      });
      const responseData = await response.json();
      responseData.forEach(element => {
        channelsOptions.push({ label: element.name, value: element.name })
      });
      this.setState({ slackChannels: channelsOptions });
    };
    fetchData();
    pause();

    this.setState({ shareLinks: false, selectTeam: false, ddShow: true, channelShow: true, inputShow: false, inputUrlShow: false, shareBtn: true, onClickFun: this.shareSlack, shareName: 'slack' });
  }
  showURL = () => {
    this.state.url = localStorage.getItem("url");
    this.setState({ shareLinks: false, selectTeam: false, ddShow: false, channelShow: false, inputShow: false, inputUrlShow: true, shareBtn: true, onClickFun: this.copy, shareName: 'copy' });
  }
  showSelectTeam = () => {
    this.setState({ shareLinks: false, selectTeam: true, ddShow: false, channelShow: false, inputShow: false, inputUrlShow: false, shareBtn: false, onClickFun: this.copy, shareName: 'copy' });
  }
  showSelectProject = () => {
    this.setState({ shareLinks: false, selectTeam: true, ddShow: false, channelShow: false, inputShow: false, inputUrlShow: false, shareBtn: false, onClickFun: this.copy, shareName: 'copy', seeFolder: false, selectName: 'Project', createOne: false, ClickupGetData: [] });
  }
  showJiraContent = () => {
    this.setState({ shareLinks: false, selectTeam: false, ddShow: true, channelShow: false, inputShow: false, inputUrlShow: false, shareBtn: true, onClickFun: this.shareJira, shareName: 'jira', JiraReady: true });
  }
  showClickupContent = () => {
    this.setState({ shareLinks: false, selectTeam: false, ddShow: true, channelShow: false, inputShow: false, inputUrlShow: false, shareBtn: true, onClickFun: this.shareClickup, shareName: 'clickup', createOne: false, clickupReady: true });
  }
  showLinkContent = () => {
    this.setState({ shareLinks: false, selectTeam: false, ddShow: true, channelShow: false, inputShow: false, inputUrlShow: false, shareBtn: true, onClickFun: this.shareLink, shareName: 'link' });
  }
  showMeetingContent = () => {
    this.setState({ shareLinks: false, selectTeam: false, ddShow: true, channelShow: false, inputShow: false, inputUrlShow: false, shareBtn: true, onClickFun: this.sendMeeting, shareName: 'gcalander' });
  }
  showMailContent = () => {
    this.setState({ shareLinks: false, selectTeam: false, ddShow: true, channelShow: false, inputShow: true, inputUrlShow: false, shareBtn: true, onClickFun: this.shareMail, shareName: 'mail' });
  }

  onSelectList = async () => {
    const response = await fetch('https://api.qandel.io/v2/api/Integration/ClickupGet/' + this.state.projectId + '?path=members&id=' + this.state.checkBoxVal, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });

    const responseData = await response.json();
    var members = [];
    responseData.members.forEach(element => {
      members.push({ label: element.username, value: element.id })
    });
    this.setState({ ClickupMembers: members, seeFolder: false });
    this.showClickupContent();
  }

  onSelectSpace = async () => {
    this.setState({ seeFolder: false, selectName: 'List', createOne: true, ClickupGetData: [] });
    var val = this.state.checkBoxVal
    if (this.state.spaceId != '') { val = this.state.spaceId }
    const response = await fetch('https://api.qandel.io/v2/api/Integration/ClickupGet/' + this.state.projectId + '?path=list&id=' + val, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });

    const responseData = await response.json();
    this.setState({ ClickupGetData: responseData.lists, addOneName: '' })
  }

  onSelectFolder = async () => {
    this.setState({ selectName: 'List', ClickupGetData: [], seeFolder: true, addOneName: '' });
    const response = await fetch('https://api.qandel.io/v2/api/Integration/ClickupGet/' + this.state.projectId + '?path=listfolder&id=' + this.state.checkBoxVal, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });
    const responseData = await response.json();
    this.setState({ ClickupGetData: responseData.lists })
  }

  onSelectTeam = async () => {
    this.setState({ ClickupGetData: [], selectName: 'Space' });
    const response = await fetch('https://api.qandel.io/v2/api/Integration/ClickupGet/' + this.state.projectId + '?path=spaces&id=' + this.state.checkBoxVal, {
      headers: {
        'Authorization': `bearer ${localStorage.getItem('jwt').split('%_END')[1].split('"')[0]}`,
      }
    });
    const responseData = await response.json();
    this.setState({ ClickupGetData: responseData.spaces })
  }

  shareSlack = () =>
    this.props.request({
      projectId: this.state.projectId,
      content: this.state.comment,
      duration: this.state.duration,
      entityId: this.props.id,
      type: this.props.entity,
      channelName: this.state.channelId
    }, `SlackShare`).then(this.handleSuccess('Slack is shared.'))

  shareLink = () =>
    this.props.request({
      projectId: this.state.projectId,
      content: this.state.comment,
      duration: this.state.duration,
      entityId: this.props.id,
      type: this.props.entity,
    }, `LinkShare`).then(() => {
      setTimeout(() => {
        this.state.url = localStorage.getItem("url"), this.showURL()
      }, 1000);
    });

  sendMeeting = () =>
  {
    if(this.state.startDateTime == '' || this.state.endDateTime == '' || this.state.summary == '' || this.state.attendeeEmails == ''){
      this.handleSuccess('Please fill all fields');
      return;
    }

    //remove duplicate emails or empty emails
    this.state.attendeeEmails = this.state.attendeeEmails.filter((v, i, a) => a.indexOf(v) === i && v != '');

    if(this.state.attendeeEmails.length > 10){
      this.handleSuccess('Maximum 10 emails can be added');
      return;
    }

    
    if(this.state.attendeeEmails.length == 0){
      this.handleSuccess('Please add atleast one email');
      return;
    }


    this.props.request({
      projectId: this.state.projectId,
      content: this.state.comment,
      duration: this.state.duration,
      summary: this.state.summary,
      attendeeEmails: this.state.attendeeEmails,
      startDateTime: this.state.startDateTime,
      endDateTime: this.state.endDateTime,
      entityId: this.props.id,
      type: this.props.entity,
    }, `GoogleCalanderShare`).then(this.handleSuccess('Google Meeting has been scheduled.')).then(this.setState({ startDateTime: '', endDateTime: '', summary: '', attendeeEmails: []}));
  }
    

  copy = () => window.navigator.clipboard.writeText(this.state.url)
    .then(this.handleSuccess('Your URL is copied.'))


  shareMail = () => this.props.request({
    projectId: this.state.projectId,
    content: this.state.comment,
    duration: this.state.duration,
    entityId: this.props.id,
    type: this.props.entity,
    emailTo: this.state.emailTo,
    subject: this.state.subject,
  }, `EmailShare`).then(this.handleSuccess('Mail is shared.'))

  shareJira = () => this.props.request({
    projectId: this.state.projectId,
    content: this.state.comment,
    duration: this.state.duration,
    entityId: this.props.id,
    type: this.props.entity,
    dueDate: this.state.dueDate,
    assignees: this.state.jiraAssignees,
    jiraProjectId: this.state.jiraProjectId,
    jiraProjectKey: this.state.jiraProjectKey,
    issueType: this.state.issueType,
    summary: this.state.summary,
  }, `JiraShare`).then(this.handleSuccess('Jira is shared.'))

  shareClickup = () => this.props.request({
    projectId: this.state.projectId,
    content: this.state.comment,
    duration: this.state.duration,
    entityId: this.props.id,
    type: this.props.entity,
    listId: this.state.checkBoxVal,
    taskName: this.state.taskName,
    dueDate: this.state.dueDate,
    assignees: this.state.assignees,
  }, `ClickupShare`).then(this.handleSuccess('ClickUp is shared.'))

  handleOpen = () => {
    this.Integrations();
    this.setState({
      isOpen: true, shareLinks: true, ddShow: false, shareBtn: false,
      channelShow: false, inputShow: false, inputUrlShow: false, createOne: false
    });
  }

  handleClose = () => {
    this.setState({
      isOpen: false, shareLinks: true, ddShow: true, channelShow: true, listId: '',
      selectTeam: false, inputShow: true, comment: '', summary: '', shareName: '', selectName: 'Team'
    });
    this.setState({ clickupReady: false, JiraReady: false });
  }

  handleSuccess = (msg) => {
    toast.success(msg);
    this.handleClose();
  }

  onChangeSelect = (event, { name, value }) => {
    this.setState({ isOpen: this.state.isOpen, comment: this.state.comment, summary: this.state.summary, duration: event.value });
  };

  onChangeChannel = (event, { name, value }) => {
    this.setState({ isOpen: this.state.isOpen, comment: this.state.comment, summary: this.state.summary, channelId: event.value });
  };


  render() {
    const { trigger, loading } = this.props;
    const { comment, isOpen, ddShow, channelShow, shareBtn, inputShow, shareName, selectName, seeFolder, integration,
      onClickFun, emailTo, subject, url, inputUrlShow, shareLinks, selectTeam, taskName, dueDate, endDateTime, startDateTime, createOne, summary, error } = this.state;

    const options = [
      { label: '6 Hours', value: '6Hours' },
      { label: '12 Hours', value: '12Hours' },
      { label: '1 Day', value: '1Day' },
      { label: '3 Days', value: '3Day' },
      { label: '7 Days', value: '7Day' },
      { label: '1 Month', value: '1Month' },
      { label: 'Without Expiration Date', value: 'WithoutDate' },
    ];

    return (
      <Popup
        open={isOpen}
        onOpen={this.handleOpen}
        // onClose={this.handleClose}
        trigger={trigger}
        content={
          <div className={styles.wrapper}>
            <div
              className={styles.closeBtn}
              onClick={this.handleClose}>
            </div>

            {shareLinks ? (<div className={styles.link}>
              <div>
                <Tooltip
                  tooltip='share Link'
                  trigger={
                    <button
                      className={styles.shareButton}
                      onClick={this.showLinkContent}
                    >
                      <img src='https://img.icons8.com/office/512/link.png' />
                    </button>
                  } />
              </div>
              <div>
                <Tooltip
                  tooltip='share Mail'
                  trigger={
                    <button
                      className={styles.shareButton}
                      onClick={this.showMailContent}
                    >
                      <img src="https://img.icons8.com/3d-fluency/512/mail.png" />
                    </button>
                  } />
              </div>
              {integration.calendly && <div >
                <Tooltip
                  tooltip='Send Meeting'
                  trigger={
                    <button
                      className={styles.shareButton}
                      onClick={this.showMeetingContent}
                    >
                      <img src="https://th.bing.com/th/id/R.cf215c8baa6ea2657f1ca4c92c99573a?rik=taJUf0MvRY4NCw&pid=ImgRaw&r=0" />
                    </button>
                  } />
              </div>}

              {integration.slack && <div >
                <Tooltip
                  tooltip='share Slack'
                  trigger={
                    <button
                      className={styles.shareButton}
                      onClick={this.showSlackContent}
                    >
                      <Icon name="integrations/slack" size="20" />
                    </button>
                  } />
              </div>}
              {integration.jira && <div >
                <Tooltip
                  tooltip='share Jira'
                  trigger={
                    <button
                      className={styles.shareButton}
                      onClick={this.JiraProjectGet}
                    >
                      <img src="https://img.icons8.com/color/512/jira.png" />
                    </button>
                  } />
              </div>}
              {integration.clickup && <div >
                <Tooltip
                  tooltip='share Click Up'
                  trigger={
                    <button
                      className={styles.shareButton}
                      onClick={this.ClickupGet}
                    >
                      <img src="https://app-cdn.clickup.com/assets/images/brand/clickup-symbol_color.svg" />
                    </button>
                  } />
              </div>
              }
            </div>) : ''}
            {inputShow ?
              <div className="block">
                <div className={styles.title}>{'EmailTo'}</div>
                <Input
                  type="email"
                  name="emailTo"
                  value={emailTo}
                  placeholder="Enter email:"
                  onChange={this.onMailChange}
                  className={styles.inputField}
                />
                <div className={styles.title}>{'Subject'}</div>
                <Input
                  type="text"
                  name="subject"
                  value={subject}
                  placeholder="Enter subject:"
                  onChange={this.onSubjectChange}
                  className={styles.inputField}
                />
              </div>
              : ''}
            {inputUrlShow ?
              <div className="block">
                <div className={styles.title}>{'URL To copy'}</div>
                <Input
                  type="email"
                  name="emailTo"
                  value={url}
                  disabled
                  className={styles.inputField}
                />
                <div className={styles.footer}>
                  <button
                    className={styles.shareButton}
                    onClick={onClickFun}
                  >
                    <img src="https://img.icons8.com/officel/512/copy-link.png" />
                    {loading ? 'Copied...' : `Copy to clipboard`}
                  </button>
                </div>
              </div>
              : ''}
            {channelShow ?
              <div className={styles.header}>
                <label htmlFor="defaultInputMode" className={styles.title}>{'Choose channel'}</label>
                <div style={{ width: '205px' }}>
                  <Select

                    name="defaultInputMode"
                    options={this.state.slackChannels}
                    onChange={this.onChangeChannel}
                    placeholder="Choose channel"
                    isSearchable={true}
                    defaultValue={this.state.channelId}
                    className={styles.dropdown}
                  />
                </div>
              </div> : ''}
            {
              shareName === 'gcalander' ?
                <div className='block'>
                  <div className={styles.header}>
                    <div className={styles.title}>{'Meeting Title'}</div>
                  </div>
                  <div className={styles.body}>
                    <textarea
                      name="summary"
                      id="summary"
                      cols="30"
                      rows="2"
                      resize="none"
                      onChange={this.editSummary}
                      value={summary}
                      placeholder="Give it a name here..."
                      className="p-4"
                    />
                  </div>
                  <div className={styles.title}>{'Meeting Start'}</div>
                  <Input
                    type="datetime-local"
                    value={startDateTime}
                    name="startDateTime"
                    onChange={this.onstartDateTimeChange}
                    className={styles.inputField}
                  />
                  <div className={styles.title}>{'Meeting End'}</div>
                  <Input
                    type="datetime-local"
                    value={endDateTime}
                    name="endDateTime"
                    onChange={this.onendDateTimeChange}
                    className={styles.inputField}
                  />

                  <div className="block" key={this.state.formValues.length - 1}>
                    <div className={styles.title}>{'EmailTo'}</div>
                    <Input
                      type="email"
                      name="emailTo"
                      placeholder="Enter email:"
                      required
                      className={cn(styles.inputField, error && styles.inputFielderr)}
                    />
                    <button onClick={this.handleEmailSubmit} className={styles.addMail}>+</button>
                    <p className={styles.error}> {error} </p>
                  </div>

                  {this.state.attendeeEmails.length >= 1 &&
                    <fieldset className={styles.fieldset}>
                      <legend>Emails you added:  </legend>
                      {this.state.attendeeEmails.map((element, index) => (
                        <div key={index}>
                          {
                            element !== "" &&
                            <button type="button" className={styles.remove} onClick={() => this.removeFormFields(index)}>
                              {element}
                              <span className={styles.remove_span}> X </span>
                            </button>
                          }
                        </div>
                      ))}
                    </fieldset>
                  }

                </div>
                : ''
            }
            {this.state.clickupReady || this.state.JiraReady ?
              <div className='block'>
                {this.state.clickupReady && <>
                  <div className={styles.title}>{'Task Name'}</div>
                  <Input
                    type="text"
                    name="taskName"
                    value={taskName}
                    placeholder="Enter Task Name:"
                    onChange={this.onTaskNameChange}
                    className={styles.inputField}
                  /> </>
                }

                <div className={styles.title}>{'Due Date'}</div>
                <Input
                  type="date"
                  value={dueDate}
                  name="Duedate"
                  onChange={this.onDueDateChange}
                  className={styles.inputField}
                />

                {this.state.JiraReady && <>
                  <div className={styles.title}>{'Issues'}</div>
                  <div style={{ marginBottom: '18px' }}>
                    <Select
                      name="Issues"
                      options={this.state.JiraIssue}
                      onChange={this.onIssuesChange}
                      isMulti={false}
                      placeholder="Select Issues"
                      isSearchable={true}
                      className={styles.dropdown}
                    />
                  </div>
                  <div className={styles.title}>{'Project Members'}</div>
                  <div style={{ marginBottom: '18px' }}>
                    <Select
                      name="ProjectMembers"
                      options={this.state.JiraMembers}
                      onChange={this.onJiraAssigneesChange}
                      placeholder="Select Projec Members"
                      isSearchable={true}
                      className={styles.dropdown}
                    />
                  </div>
                </>}
                {this.state.clickupReady && <>
                  <div className={styles.title}>{'Assignees'}</div>
                  <div style={{ marginBottom: '18px' }}>
                    <Select
                      name="assignees"
                      options={this.state.ClickupMembers}
                      onChange={this.onAssigneesChange}
                      isMulti={true}
                      placeholder="Select Assignees"
                      isSearchable={true}
                      //defaultValue={this.state.duration}
                      className={styles.dropdown}
                    />
                  </div>
                </>}
              </div> : ''}
            {!this.state.clickupReady && selectName == 'List' || selectName == 'Folder' ?
              <div className={styles.folder}>
                <button
                  className={cn(styles.shareButton, seeFolder ? '' : styles.bg)}
                  onClick={this.onSelectSpace}
                >
                  All
                </button>
                <button
                  className={cn(styles.shareButton, seeFolder ? styles.bg : '')}
                  onClick={this.folderList}
                >
                  Folder
                </button>
              </div>
              : ''}
            {selectTeam ?
              <div className='block'>
                {createOne &&
                  <div className={styles.addBtn}>
                    <Input
                      type="text"
                      placeholder={`add ${selectName} name...`}
                      name="create folder"
                      value={this.state.addOneName}
                      onChange={this.onFolderNChange}
                      className={styles.inputField}
                    />
                    <Tooltip
                      tooltip={`create ${selectName}`}
                      trigger={
                        <button
                          className={styles.shareButton}
                          onClick={selectName == 'Folder' ? this.createFolder : !seeFolder ? this.createList : this.createFolderList}
                        > +
                        </button>
                      }
                    />
                    <span className={styles.required}>*You must add untaken {selectName} name </span>
                  </div>
                }
                {this.state.ClickupGetData.length == '0' ?
                  <span className={styles.noData}> No Data Here </span> : <>
                    {this.state.ClickupGetData.map(object =>
                    (<Form.Field className={styles.form}>
                      <div className="flex items-center mb-5">
                        <Checkbox
                          className="font-medium mr-3"
                          type="radio"
                          id={object.id}
                          name={object.key}
                          onClick={this.onChangeOption}
                        />
                        <div className="flex items-center cursor-pointer">
                          {object.avatar ? <img src={object.avatar} /> : <img src="https://img.icons8.com/fluency/512/no-image.png" />}
                          <span className="ml-2"> {object.name}</span>
                        </div>
                      </div>
                    </Form.Field>)
                    )}</>
                }
                <div className={styles.footer}>
                  <button
                    className={styles.shareButton}
                    onClick={
                      selectName == 'Team' ? this.onSelectTeam :
                        selectName == 'List' ? this.onSelectList :
                          selectName == 'Folder' ? this.onSelectFolder :
                            selectName == 'Project' ? this.onSelectJiraProject : this.onSelectSpace
                    }
                  >
                    <img src="https://app-cdn.clickup.com/assets/images/brand/clickup-symbol_color.svg" />
                    {loading ? 'Selected...' : `Select ${selectName}`}
                  </button>
                </div>
              </div>
              : ''}
            {ddShow && shareName != 'copy' ? (<>
              <div className={styles.header}>
                <label htmlFor="defaultInputMode" className={styles.title}>{'Expiration Duration'}</label>
                <div style={{ width: '205px' }}>
                  <Select

                    name="defaultInputMode"
                    options={options}
                    onChange={this.onChangeSelect}
                    placeholder="Expiration Duration"
                    isSearchable={true}
                    defaultValue={this.state.duration}
                    className={styles.dropdown}
                  />
                </div>
              </div>
              <div className={styles.header}>
                <div className={styles.title}>{'Comment'}</div>
              </div>
              <div className={styles.body}>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows={shareName == 'jira' ? '2' : '6'}
                  resize="none"
                  onChange={this.editMessage}
                  value={comment}
                  placeholder="Type here..."
                  className="p-4"
                />
              </div>
            </>) : ('')}
            {this.state.JiraReady &&
              <div>
                <div className={styles.header}>
                  <div className={styles.title}>{'Summary'}</div>
                </div>
                <div className={styles.body}>
                  <textarea
                    name="summary"
                    id="summary"
                    cols="30"
                    rows="2"
                    resize="none"
                    onChange={this.editSummary}
                    value={summary}
                    placeholder="Type here..."
                    className="p-4"
                  />
                </div>
              </div>}
            {shareBtn ?
              <div className={styles.footer}>
                <div>
                  <button
                    className={styles.shareButton}
                    onClick={onClickFun}
                  >
                    {shareName == 'slack' ? <Icon name="integrations/slack" size="20" marginRight='10' /> : ''}
                    {shareName == 'jira' ? <img src="https://img.icons8.com/color/512/jira.png" /> : ''}
                    {shareName == 'link' ? <img src='https://img.icons8.com/office/512/link.png' /> : ''}
                    {shareName == 'mail' ? <img src="https://img.icons8.com/3d-fluency/512/mail.png" /> : ''}
                    {shareName == 'gcalander' ? <img src="https://th.bing.com/th/id/R.cf215c8baa6ea2657f1ca4c92c99573a?rik=taJUf0MvRY4NCw&pid=ImgRaw&r=0" /> : ''}
                    {shareName == 'clickup' ? <img src="https://app-cdn.clickup.com/assets/images/brand/clickup-symbol_color.svg" /> : ''}


                    {loading ? shareName != 'gcalander' ? 'Sharing...' : 'Sending a Meeting' : shareName != 'gcalander' ? `Share` : 'Send Meeting'}
                  </button>
                </div>
              </div>
              : ''}
          </div>
        }
        on="click"
        position="top right"
        className={styles.popup}
      // hideOnScroll
      />
    );
  }
}
